@import url(./assets/font/text/poppins.css);
@import url(./assets/font/icon/material-icons.css);
@import url(./assets/css/bootstrap.min.css);
:root{
    --primaryColor: #e65100;
    --primaryLightColor: #fff1ea;
    --accentColor: #2196f3;
    --accentLightColor: #d9efff;
    --mat-mdc-focus-indicator-border-color: transparent;
}
*{
    font-family: 'Poppins', sans-serif !important;
    box-sizing: border-box; 
}
html, body{
    height: 100%;
    scroll-behavior: smooth;
}
body{
    background-color: #fff;
    color: #212529;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0px;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}
h1, h2, h3, h4, h5, h6, p, ul, li{
    margin: 0px;
    padding: 0px;
}
a{
    color: #212529;
    text-decoration: none;
}
button:focus{
    outline:0;
}

// Content Section Class Start
.contentSection{
    position: relative;
    background-color: var(--primaryLightColor);
    padding: 60px 0px;
    img{
        width: 100%;
        height: auto;
        &.image1{
            position: sticky;
            top: 100px;
            left: 0px;
        }
        &.image2{
            display: block;
            width: auto;
            height: 340px;
            margin: 0px auto;
        }
    }
    .content{
        position: relative;
        h2{
            color: var(--primaryColor);
            font-size: 22px;
            font-weight: 600;
            line-height: 1.3;
            margin: 0px 0px 6px;
        }
        h4{
            color: var(--primaryColor);
            font-size: 18px;
            font-weight: 500;
            line-height: 1.3;
            margin: 0px 0px 10px;
        }
        p{
            margin: 0px 0px 14px;
            font-size: 16px;
            text-align: justify;
            &.quote{
                display: inline-block;
                background-color: #ffffff;
                color: var(--accentColor);
                font-size: 16px;
                font-weight: 500;
                line-height: 1.7;
                border-left: 4px solid var(--accentColor);
                padding: 14px;
            }
            a{
                position: relative;
                color: var(--accentColor);
            }
        }
        ul{
            list-style-type: none;
            li{
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                margin: 0px 0px 12px;
                img{
                    width: 17px;
                    transform: translate(0px, 4px);
                }
                p{
                    margin: 0px;
                }
            }
        }
        .icon{
            position: relative;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 12px;
            .iconText{
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 6px;
                mat-icon{
                    background-color: var(--accentColor);
                    color: #fff;
                    width: 40px;
                    height: 40px;
                    line-height: 39px;
                    text-align: center;
                    border-radius: 4px;
                }
                p{
                    margin: 0px;
                    color: var(--accentColor);
                    font-weight: 500;
                }
            }
        }
    }
    .imageTitle{
        margin: 0px 0px 10px;
        font-weight: 500;
        text-align: center;
    }
}
// Content Section Class End

// Table Class Start
.tableSection{
    position: relative;
    padding: 20px 10px;
    &.popup{
        padding: 0px;
    }
    .search{
        position: relative;
        .mat-mdc-form-field-subscript-wrapper{
            margin: 0px 0px 10px;
            &::before{
                display: none;
            }
        }
    }
    .action{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
    }
    .table-responsive{
        position: relative;
        margin: 10px 0px 0px;
        overflow: auto;
        table{
            thead{
                tr{
                    th{
                        &.eventDescription{
                            width: 40%;
                        }
                    }
                }
            }
            tbody{
                tr{
                    td{
                        .status{
                            display: inline-block;
                            min-width: 92px;
                            background-color: var(--primaryLightColor);
                            color: var(--primaryColor);
                            text-align: center;
                            border-radius: 4px;
                            padding: 4px;
                            &.login{
                                background-color: var(--primaryLightColor);
                                color: var(--primaryColor);
                            }
                            &.logout{
                                background-color: var(--accentLightColor);
                                color: var(--accentColor);
                            }
                        }
                        .date{
                            display: block;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
// Table Class End

// Snack Bar Class Start
.snackBarInfo{
    --mdc-snackbar-container-color: var(--accentColor);
    --mat-snack-bar-button-color: #ffffff;
}
.snackBarSuccess{
    --mdc-snackbar-container-color: #198754;
    --mat-snack-bar-button-color: #ffffff;
}
.snackBarWarning{
    --mdc-snackbar-container-color: #ffc107;
    --mdc-snackbar-supporting-text-color: #000000;
    --mat-snack-bar-button-color: #000000;
}
.snackBarError{
    --mdc-snackbar-container-color: #dc3545;
    --mat-snack-bar-button-color: #ffffff;
}
// Snack Bar Class End

// Common Popup Class Start
.commonPopup{
    position: relative;
    padding: 0px 10px;
    &:has(mat-dialog-actions){
        mat-dialog-content{
            max-height: calc(100vh - 103px);
            pdf-viewer{
                width: 100%;
                height: calc(100vh - 165px);  
            }
        }
    }
    .title{
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 16px;
        background-color: var(--primaryLightColor);
        &::before{
            display: none;
        }
        h4{
            color: var(--primaryColor);
            font-size: 17px;
            font-weight: 600;
            line-height: 1.3;
        }
    }
    mat-dialog-content{
        position: relative;
        max-height: calc(100vh - 48px);
        padding: 16px !important;
        pdf-viewer{
            width: 100%;
            height: calc(100vh - 110px);  
        }
        mat-progress-bar{
            position: absolute;
            top: 0px;
            left: 0px;
        }
    }
    mat-dialog-actions{
        position: relative;
        border-top: 1px solid #e4e4e4;
        padding: 9px 16px;
    }
}
// Common Popup Class End

// Form Section Class Start
.formSection{
    position: relative;
    .formField{
        position: relative;
        margin: 0px 0px 10px;
    }
}
mat-form-field{
    position: relative;
    width: 100%;
    .mdc-floating-label{
        top: 22px !important;
    }
    .mdc-floating-label--float-above{
        top: 26px !important;
    }
    .mat-mdc-form-field-infix{
        min-height: 46px;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
        line-height: 34px;
    }
    textarea{
        min-height: 160px;
    }
    mat-error{
        font-size: 12px;
    }
    &.removeErrorSpace{
        .mat-mdc-form-field-subscript-wrapper{
            &::before{
                display: none;
            }
        }
    }
}
.verificationSeaction{
    h3{
        color: var(--accentColor);
        font-size: 17px;
        font-weight: 600;
    }
    p{
        margin: 4px 0px 10px;
    }
}
.optVerificaionField{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 14px;
    input{
        position: relative;
        width: 42px;
        height: 42px;
        font-size: 16px;
        text-align: center;
        border-radius: 2px;
        border: 1px solid #9e9e9e;
        padding: 4px 6px;
        &.ng-touched{
            &.ng-invalid{
                border-color: var(--primaryColor);
            }
        }
    }
}
// Form Section Class End

// Dropdown Menu Class Start
.profileDropdown{
    .mat-mdc-menu-content{
        width: 220px;
        padding: 0px !important;
    }
}
// Dropdown Menu Class End

.buttonLoader{
    display: inline-block !important;
    transform: translate(-3px, 3px);
    &.color1{
        --mdc-circular-progress-active-indicator-color: #fff !important;
    }
}

button{
    mat-icon{
        width: 22px !important;
        height: 22px !important;
        font-size: 22px !important;
    }
}

.lineLimit{
    overflow: hidden !important;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   &.l-1{
    -webkit-line-clamp: 1;
    line-clamp: 1;
   }
   &.l-2{
    -webkit-line-clamp: 2;
    line-clamp: 2;
   }
   &.l-3{
    -webkit-line-clamp: 3;
    line-clamp: 3;
   }
}

.m-t-10{
    margin-top: 10px;
}
.m-t-20{
    margin-top: 20px;
}